<template>
	<v-menu
		ref="datepicker"
		v-model="datepicker"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="auto"
		:disabled="readonly"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				:id="id"
				:label="label"
				:loading="loading"
				:disabled="disabled"
				:rules="rules"
				readonly
				:style="inlineStyle"
				outlined
				class="pt-0"
				:class="[
					hideTopMargin ? `${contentClass} ${customClass}` : `mt-3 ${contentClass} ${customClass}`,
					{ required: required && ((date && !date.length) || !date) },
				]"
				v-model="dateFormatted"
				:placeholder="btxPlaceholder"
				hide-details
				:clearable="clearable"
				:prepend-inner-icon="hidePrefixIcon ? '' : 'mdi-calendar'"
				v-bind="attrs"
				@blur="date = parseDate(dateFormatted)"
				v-on="on"
				@click:clear="$emit('click:clear', true)"
			></v-text-field>
		</template>
		<v-date-picker
			v-model="date"
			no-title
			:type="pickerType"
			:min="minDate"
			:max="maxDate"
			:reactive="reactive"
			:scrollable="scrollable"
			:allowed-dates="disableSaturdaysAndSundays"
			@blur="$emit('blur')"
			:disabled="disabled"
			:class="{ required: required && ((date && !date.length) || !date) }"
			:readonly="readonly"
			@input="datepicker = false"
		></v-date-picker>
	</v-menu>
</template>

<script>
import MomentJS from "moment-timezone";
export default {
	name: "date-picker",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		holidays: {
			type: Array,
			default: () => {
				return [];
			},
		},
		value: {
			type: [Date, String],
			default: null,
		},
		reactive: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		scrollable: {
			type: Boolean,
			default: false,
		},
		enjoyWeekEnd: {
			/* True to disable Saturday and Sunday of all weeks */
			type: Boolean,
			default: false,
		},
		inlineStyle: {
			type: Object,
			default: () => {},
		},
		hideTopMargin: {
			type: Boolean,
		},
		hidePrefixIcon: {
			type: Boolean,
			default: false,
		},
		minDate: {
			type: String,
			default: undefined,
		},
		maxDate: {
			type: String,
			default: undefined,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		placeholder: {
			type: String,
			default: "Date",
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		contentClass: {
			type: Object,
			default: null,
		},
		customClass: {
			type: String,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		disablePastFrom: {
			type: String,
		},
		disableFutureFrom: {
			type: String,
		},
		pickerType: {
			type: String,
			default: "date",
		},
	},
	data() {
		return {
			date: null,
			datepicker: false,
			dateFormatted: null,
		};
	},
	watch: {
		// minDate() {
		// 	this.date = null;
		// },
		maxDate() {
			this.date = null;
		},
		date() {
			this.emitValue();
		},
		value() {
			this.date = this.value;
		},
	},
	methods: {
		emitValue() {
			this.dateFormatted = this.pickerType == "date" ? this.formatDate(this.date) : this.date;
			this.$emit("input", this.date);
			this.$emit("change", this.date);
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		parseDate(date) {
			if (!date) return null;
			const [day, month, year] = date.split("/");
			return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
		},
		/* To disable past dates */
		disablePastDates(val) {
			if (this.disablePastFrom) {
				return val >= new Date(this.disablePastFrom).toISOString().substr(0, 10);
			} else {
				return new Date();
			}
		},
		/* To disable future dates */
		disableFutureDates(val) {
			return this.disableFutureFrom
				? val <= new Date(this.disableFutureFrom).toISOString().substr(0, 10)
				: new Date();
		},
		/* To disable Saturdays and Sundays */
		disableSaturdaysAndSundays(allowed) {
			let holidayExist = false;
			if (this.holidays.length && this.holidays.includes(allowed)) {
				holidayExist = true;
			}

			if (this.enjoyWeekEnd) {
				let value = MomentJS(allowed);
				let daynum = value.day();
				if (daynum != 0 && daynum != 6 && !holidayExist) {
					return allowed;
				}
			} else {
				if (!holidayExist) return allowed;
			}
		},
	},
	computed: {
		btxPlaceholder() {
			return this.placeholder + " [DD/MM/YYYY]";
		},
	},
	mounted() {
		this.date = this.value;
		this.$nextTick(() => {
			this.emitValue();
		});
	},
};
</script>
