<template>
	<div style="width: 100%">
		<v-layout class="d-flex justify-space-between" style="width: 100%">
			<v-flex style="width: 100%" class="d-flex justify-space-between align-center">
				<div class="d-flex justify-space-between align-center" style="width: 100%">
					<v-flex class="py-0 d-flex">
						<!-- v-select__selection--comma -->
						<v-select
							v-model="status"
							class="listing-select"
							hide-details
							:disabled="pageLoading"
							:items="backlinks_status"
							solo
							style="max-width: 220px !important"
							@change="commanFilter"
							:menu-props="{ offsetY: true, contentClass: 'rounded-lg border' }"
						>
							<!-- :menu-props="{ bottom: true, offsetY: true }" -->
							<!-- v-on:change="statusFilterRows($event)" -->
							<template #item="{ item }">
								<div class="d-flex my-1 align-center">
									<v-chip class="px-2 mr-2" dark :color="item.status_color">{{ item.count }}</v-chip>
									<div class="fw-500">{{ item.text }}</div>
								</div>
								<!-- <v-list-item-content>
									<v-list-item-title v-text="item.text"></v-list-item-title>
										<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
								</v-list-item-content>
								<v-list-item-action>
									<v-avatar size="26" :color="item.status_color">
										<span class="white--text">{{ item.count }}</span>
									</v-avatar>
								</v-list-item-action> -->
							</template>
						</v-select>
					</v-flex>
					<div class="fw-600 mr-2">Filter By</div>
					<div class="pr-2">
						<SelectInput
							:items.sync="seoActivityList"
							id="category"
							hide-details
							name="text"
							class="my-2"
							:loading="pageLoading"
							style="margin: 0 !important; padding: 0 !important"
							placeholder="Activity"
							item-value="value"
							item-text="title"
							v-model="backlinkActivity"
							clearable
							@change="commanFilter"
							:menu-props="{ offsetY: true, closeOnClick: true }"
							noStyleItem="title"
						></SelectInput>
					</div>
					<div class="pr-2">
						<TextInput
							hide-details
							hide-top-margin
							:loading="pageLoading"
							v-model="targetUrlFilter"
							clearable
							style="width: 300px"
							class="w-100"
							placeholder="Target Url"
							@input="textSearchFilter"
						></TextInput>
					</div>
					<div class="pr-2">
						<TextInput
							hide-details
							hide-top-margin
							clearable
							:loading="pageLoading"
							style="width: 300px"
							class="mr-4 w-100"
							v-model="anchorTextFilter"
							placeholder="Anchor text"
							@input="textSearchFilter"
						></TextInput>
					</div>
					<div class="pr-2">
						<DateRangePicker
							:id="+new Date()"
							hide-details
							hide-top-margin
							clearable
							:loading="pageLoading"
							@click:clear="(date_range = null), searchWithDate()"
							style="width: 300px"
							placeholder="Date Range"
							v-model="date_range"
							content-class="rounded-lg border"
							@change="searchWithDate"
						></DateRangePicker>
						<!--  -->
					</div>
					<!-- <v-tooltip top>
						<template #activator="{ on, attrs }">
							<v-btn
								color="blue darken-4 text-white"
								v-on="on"
								v-bind="attrs"
								x-small
								depressed
								min-height="26px"
								tile
							>
								<v-icon>search</v-icon>
							</v-btn>
						</template>
						<span>Search</span>
					</v-tooltip> -->
					<!-- <div class="d-flex">
						<div>
							<v-chip outlined label text color="cyan"
								><span class="font-level-2-bold mr-1"> Backlink:</span>900</v-chip
							>
						</div>
						<div class="ml-4">
							<v-chip outlined label text color="orange"
								><span class="font-level-2-bold mr-1"> Domain:</span>70</v-chip
							>
						</div>
						<div class="ml-4">
							<v-chip outlined label text color="orange"
								><span class="font-level-2-bold mr-1"> Do Follow:</span>500</v-chip
							>
						</div>
						<div class="ml-4">
							<v-chip outlined label text color="teal darken-1"
								><span class="font-level-1-bold mr-1"> No Follow:</span>300</v-chip
							>
						</div>
					</div> -->
					<div>
						<template v-if="getPermission('backlink_library_settings:create')">
							<!-- <v-tooltip top>
								<template #activator="{ on, attrs }">
									<v-btn
										depressed
										:disabled="pageLoading"
										tile
										color="blue darken-2"
										class="text-white"
										v-on="on"
										v-bind="attrs"
										href="/media/sample-files/Sample_Backlink.csv"
										download
									>
										<v-icon left> mdi-download </v-icon>
										Sample CSV
									</v-btn>
								</template>
								<span>Download Sample CSV File</span>
							</v-tooltip> -->
							<v-btn color="red darken-4 text-white" depressed tile @click="createBackLinkDialog = true">
								<v-icon>mdi-plus</v-icon>
								Add
							</v-btn>
						</template>
						<!-- <v-btn color="blue darken-4 text-white" class="ml-2" depressed tile
							><v-icon>mdi-cog</v-icon></v-btn
						> -->

						<v-menu
							offset-y
							class="ml-2"
							max-width="168px"
							:close-on-content-click="true"
							nudge-left="90px"
							nudge-bottom="5px"
							content-class="border rounded-lg"
						>
							<template #activator="{ on: menu, attrs }">
								<v-tooltip top>
									<template #activator="{ on: tooltip }">
										<v-btn
											color="blue darken-4"
											class="ml-2"
											x-small
											min-height="26px"
											tile
											depressed
											v-bind="attrs"
											v-on="{ ...tooltip, ...menu }"
										>
											<v-icon color="#fff">mdi-menu</v-icon>
										</v-btn>
									</template>
									<span>Menu</span>
								</v-tooltip>
							</template>
							<v-list>
								<v-list-item
									class="py-1 border-bottom align-center fw-600 text-uppercase cursor-pointer px-3"
									href="/media/sample-files/Sample_Backlink.csv"
								>
									<v-icon left color="blue darken-4"> mdi-download </v-icon> Sample File
								</v-list-item>

								<v-list-item
									@click="exportCSV"
									class="py-1 border-bottom align-center fw-600 text-uppercase cursor-pointer px-3"
								>
									<v-icon left color="blue darken-4">mdi-export</v-icon> Export
								</v-list-item>

								<v-list-item
									@click="importDialog = true"
									class="py-1 align-center fw-600 text-uppercase cursor-pointer px-3"
									v-if="getPermission('backlink_library_settings:create')"
								>
									<v-icon left color="blue darken-4">ios_share</v-icon> Import
								</v-list-item>
							</v-list>
						</v-menu>

						<!-- <v-tooltip top>
							<template #activator="{ on, attrs }">
								<v-btn
									color="blue darken-4 text-white"
									v-on="on"
									v-bind="attrs"
									class="ml-2"
									depressed
									x-small
									min-height="26px"
									tile
									@click="exportCSV()"
								>
									<v-icon>mdi-export</v-icon>
								</v-btn>
							</template>
							<span>Export</span>
						</v-tooltip> -->

						<!-- <template v-if="getPermission('backlink_library_settings:create')">
							<v-tooltip top>
								<template #activator="{ on, attrs }">
									<v-btn
										color="blue darken-4 text-white"
										v-on="on"
										v-bind="attrs"
										class="ml-2"
										depressed
										tile
										min-height="26px"
										x-small
										@click="importDialog = true"
									>
										<v-icon>ios_share</v-icon>
									</v-btn>
								</template>
								<span>Import</span>
							</v-tooltip>
						</template> -->

						<v-tooltip top>
							<template #activator="{ on, attrs }">
								<v-btn
									color="blue darken-4 text-white"
									v-on="on"
									v-bind="attrs"
									class="ml-2"
									depressed
									tile
									min-height="26px"
									x-small
									@click="showSummary = !showSummary"
								>
									<v-icon>mdi-file-chart-outline</v-icon>
								</v-btn>
							</template>
							<span>Summary</span>
						</v-tooltip>
					</div>
				</div>
			</v-flex>

			<Dialog
				v-if="createBackLinkDialog && getPermission('backlink_library_settings:create')"
				:dialog="createBackLinkDialog"
				:dialog-width="+ddwidth"
				@close="createBackLinkDialog = false"
				dense
			>
				<template #title>
					<div style="width: 100%" class="d-flex justify-space-between">
						<div class="d-flex">
							<div>ADD BackLink</div>
						</div>
						<!-- <v-text-field dense hide-details type="number" step="10" v-model="ddwidth"></v-text-field> -->
					</div>
				</template>
				<template #body>
					<AddMultipleBackLink
						:seoActivityList="seoActivityList"
						:seoCategoryList="seoCategoryList"
						:usersList="usersList"
						@selectBacklinkRow="backlinkdata"
						:seoId="seoId"
						:projectId="projectId"
					></AddMultipleBackLink>
				</template>
				<template #action>
					<v-btn
						@click.stop.prevent="createOrUpdateBacklink"
						color="blue darken-4"
						class="white--text"
						depressed
						tile
						:disabled="pageLoading || (Array.isArray(get_selectedRows) && !get_selectedRows.length)"
					>
						Add
					</v-btn>
					<v-btn
						@click="(createBackLinkDialog = false), backlinkdata()"
						depressed
						tile
						:disabled="pageLoading"
					>
						Close
					</v-btn>
				</template>
			</Dialog>
		</v-layout>
		<v-progress-linear
			color="orange darken-2"
			v-if="pageLoading"
			indeterminate
			height="6"
		></v-progress-linear>
		<v-layout
			:class="['page-summary', { show: showSummary }, 'mb-2', { 'mt-0': showSummary && !pageLoading }]"
			style="overflow-y: hidden"
		>
			<v-flex
				class="summary-details pt-2 bg-light-blue rounded-lg elevation-3"
				:style="{ padding: '0px 5px 5px 0px', border: `1px solid #2196f3` }"
			>
				<!-- @click="(status = null), commanFilter()" -->
				<div class="text-capitalize ml-2 fw-600 blue--text" style="font-size: 16px !important">
					Total Backlinks
				</div>
				<div class="d-flex align-itms-center">
					<div style="position: relative; bottom: 2.2rem">
						<v-icon style="margin-left: -10px" color="blue"> mdi-file-chart-outline </v-icon>
					</div>
					<div class="number blue--text ms-2 mt-2">{{ getValue(count, "0.total_count") || 0 }}</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details pt-2 bg-light-red rounded-lg elevation-3"
				:style="{ padding: '0px 5px 5px 0px', border: `1px solid #f44336` }"
			>
				<div class="text-capitalize ml-2 fw-600 red--text" style="font-size: 16px !important">
					Last Month Backlinks
				</div>
				<div class="d-flex align-itms-center">
					<div style="position: relative; bottom: 2.2rem">
						<v-icon style="margin-left: -10px" color="red"> mdi-file-chart-outline </v-icon>
					</div>
					<div class="number red--text ms-2 mt-2">{{ getValue(count, "0.last_month_total") || 0 }}</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details pt-2 bg-light-orange rounded-lg elevation-3"
				:style="{ padding: '0px 5px 5px 0px', border: `1px solid #ff9800` }"
			>
				<!-- @click="(status = 3), commanFilter()" -->
				<div class="text-capitalize ml-2 fw-600 orange--text" style="font-size: 16px !important">
					This Month pending/Assigned
				</div>
				<div class="d-flex align-itms-center">
					<div style="position: relative; bottom: 2.2rem">
						<v-icon style="margin-left: -10px" color="orange"> mdi-file-chart-outline </v-icon>
					</div>
					<div class="number orange--text ms-2 mt-2">{{ getValue(count, "0.pendingCount") || 0 }}</div>
				</div>
			</v-flex>
		</v-layout>

		<div class="d-flex justify-space-between" style="width: 100%">
			<div style="width: 100%" class="detail-card bg-white">
				<!-- <div
						@click="credentials_tab = !credentials_tab"
						class="card--header d-flex align-items-center pa-2 border-bottom grey lighten-5"
					>
						<div class="font-level-3-bold">Credentials</div>
						<v-spacer></v-spacer>
						<v-icon>{{ credentials_tab ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon>
					</div> -->

				<div class="v-data-table bt-table theme--light">
					<div class="v-data-table__wrapper table_height">
						<v-form ref="subTaskForm" v-model="backlinkFormValidity" lazy-validation>
							<table style="table-layout: fixed" width="100%">
								<thead>
									<!-- Indexed  ,Status,target url, Anchor,Date,Do Follow-->
									<tr>
										<th style="width: 50px" class="simple-table-th">#</th>
										<th
											v-if="getPermission('backlink_library_settings:delete')"
											style="width: 100px"
											class="simple-table-th text-center"
										>
											Action
										</th>
										<th style="width: 300px" class="simple-table-th">
											Domain
											<!-- <v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('domain', sortdata.order)"
											>
												{{ sortdata.column == "domain" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon> -->
										</th>
										<th style="width: 250px" max-width="150px" class="simple-table-th">
											Live URL
											<!-- <v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('live_url', sortdata.order)"
											>
												{{ sortdata.column == "live_url" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon> -->
										</th>
										<th style="width: 100px" max-width="150px" class="simple-table-th">
											Status
											<!-- <v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('status', sortdata.order)"
											>
												{{ sortdata.column == "status" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon> -->
										</th>
										<th style="width: 90px" max-width="250px" class="simple-table-th">
											Indexed
											<!-- <v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('indexed', sortdata.order)"
											>
												{{ sortdata.column == "indexed" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon> -->
										</th>
										<th style="width: 250px" max-width="150px" class="simple-table-th">
											Target URL
											<!-- <v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('target_url', sortdata.order)"
											>
												{{ sortdata.column == "target_url" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon> -->
										</th>
										<th style="width: 250px" max-width="250px" class="simple-table-th">
											Anchor Text
											<!-- <v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('anchor_text', sortdata.order)"
											>
											{{ sortdata.column == "anchor_text" ? sortdata.icon : "mdi-filter-variant" }}
										</v-icon> -->
											<!-- @click="sortBacklink('anchor_text', sort_type['anchor_text'].order)" -->
										</th>
										<th style="width: 150px" max-width="150px" class="simple-table-th">
											Do Follow
											<!-- <v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('do_follow', sortdata.order)"
											>
												{{ sortdata.column == "do_follow" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon> -->
										</th>
										<th style="width: 150px" max-width="150px" class="simple-table-th">
											Date
											<v-icon color="blue darken-4" right small @click="sortBacklink('date', sortdata.order)">
												{{ sortdata.column == "date" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon>
										</th>
										<th style="width: 180px" max-width="150px" class="simple-table-th">
											Activity
											<v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('activity', sortdata.order)"
											>
												{{ sortdata.column == "activity" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon>
										</th>
										<th style="width: 150px" max-width="150px" class="simple-table-th">
											Country
											<v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('country', sortdata.order)"
											>
												{{ sortdata.column == "country" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon>
										</th>
										<th style="width: 150px" max-width="150px" class="simple-table-th">
											Category
											<v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('category', sortdata.order)"
											>
												{{ sortdata.column == "category" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon>
										</th>
										<th style="width: 150px" max-width="150px" class="simple-table-th">
											Executive
											<v-icon
												color="blue darken-4"
												right
												small
												@click="sortBacklink('executive', sortdata.order)"
											>
												{{ sortdata.column == "executive" ? sortdata.icon : "mdi-filter-variant" }}
											</v-icon>
										</th>
									</tr>
								</thead>
								<tbody>
									<!-- v-clickoutside="clickoutside" -->
									<!-- @click="addRow(index)" -->
									<!-- <template v-if="pageLoading">
										<tr>
											<td colspan="10" class="mx-20 my-40 text-center">
												<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
											</td>
										</tr>
									</template> -->

									<template v-if="Array.isArray(tbody) && tbody.length">
										<tr class="task_row" v-for="(row, index) in tbody" :key="'backlink_table_' + index">
											<td
												width="10px"
												class="td_column text-center cursor-default simple-table-td border-top-light-grey"
											>
												{{ (page - 1) * 15 /* per_page */ + (index + 1) }}
											</td>

											<!-- Actions -->
											<td
												v-if="getPermission('backlink_library_settings:delete')"
												class="td_column cursor-default simple-table-td text-center border-top-light-grey"
											>
												<div :class="{ 'd-flex align-center justify-center': activeRowId != row.id }">
													<v-tooltip v-if="activeRowId != row.id" top content-class="custom-top-tooltip">
														<template v-slot:activator="{ on, attrs }">
															<v-icon
																v-bind="attrs"
																v-on="on"
																:disabled="tbody && Array.isArray(tbody) && tbody.length == 1 && totalPage == 1"
																@click.stop.prevent="deleteConfirm(row.id, index)"
																size="20"
																class="cursor-pointer p-1"
																:style="{
																	border: `1px solid ${
																		tbody && Array.isArray(tbody) && tbody.length == 1 ? 'rgba(0, 0, 0, 0.38)' : 'red'
																	}`,
																	borderRadius: '15%',
																}"
																color="red"
																>mdi-delete</v-icon
															>
														</template>
														<span>Delete</span>
													</v-tooltip>

													<div v-else class="d-flex align-center justify-space-around">
														<div>
															<v-tooltip top content-class="custom-top-tooltip" class="cursor-pointer">
																<template #activator="{ on, attrs }">
																	<v-icon
																		v-on="on"
																		v-bind="attrs"
																		color="green"
																		class="p-1"
																		size="20"
																		@click="submitFunction"
																		:style="{
																			border: `1px solid #4caf50`,
																			borderRadius: '15%',
																		}"
																	>
																		mdi-check-circle
																	</v-icon>
																</template>
																<span>Save</span>
															</v-tooltip>
														</div>
														<div>
															<v-tooltip top content-class="custom-top-tooltip" class="cursor-pointer">
																<template #activator="{ on, attrs }">
																	<v-icon
																		v-on="on"
																		v-bind="attrs"
																		color="red"
																		class="p-1"
																		size="20"
																		@click="clickoutside"
																		:style="{
																			border: `1px solid red`,
																			borderRadius: '15%',
																		}"
																	>
																		mdi-close-circle
																	</v-icon>
																</template>
																<span>Cancel</span>
															</v-tooltip>
														</div>
													</div>
												</div>
											</td>

											<!-- <td
										@click="editRow($event, index, row.id)"
										:class="row.isEditable ? 'px-2' : 'px-3'"
										width="250px"
										style="padding: 0"
										class="td_column cursor-default simple-table-td border-top-light-grey py-3"
									>
										<SelectInput
											:items.sync="domainList"
											v-if="row.isEditable"
											hideTopMargin
											id="domain"
											@change="domainChange"
											style="margin: 0 !important; padding: 0 !important"
											hide-details
											dense
											name="text"
											value="value"
											placeholder="Domain"
											v-model="row.domain"
										></SelectInput>
										<span v-else>{{ row.domain }}</span>
									</td> -->

											<!-- Domain -->
											<!-- :class="activeRowId == row.id ? 'px-2' : 'px-4'" -->
											<td
												width="10px"
												class="td_column cursor-pointer text-truncate simple-table-td border-top-light-grey px-4"
											>
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<span v-on="on" v-bind="attrs">{{ row.domain }}</span>
													</template>
													{{ row.domain }}
												</v-tooltip>
											</td>

											<!-- Live URL -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												style="padding: 0; white-space: break-spaces"
												class="td_column cursor-default simple-table-td border-top-light-grey"
												:class="activeRowId == row.id ? 'px-2' : 'px-4'"
											>
												<div @click.stop v-if="activeRowId == row.id">
													<TextInput
														dense
														hide-top-margin
														hide-details
														:disabled="pageLoading"
														style="margin: 0 !important; padding: 0 !important"
														:loading="pageLoading"
														class="my-2 backlink-text-inp"
														id="live_url"
														placeholder="Live Url"
														v-model.trim="updateBacklinkData.live_url"
														:rules="[vrules.validUrl(updateBacklinkData.live_url, 'Live Url', true)]"
													>
													</TextInput>
												</div>
												<template v-else>
													<v-tooltip top class="cursor-pointer">
														<template #activator="{ on, attrs }">
															<div v-on="on" v-bind="attrs" class="text-truncate cursor-pointer">
																{{ row.live_url }}
															</div>
														</template>
														{{ row.live_url }}
													</v-tooltip>
												</template>
											</td>

											<!-- Status -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												width="250px"
												style="padding: 0"
												class="td_column cursor-default simple-table-td border-top-light-grey"
											>
												<v-menu
													offset-y
													min-width="100px"
													:disabled="ORAny([row.status == 1, row.status == 2, row.status == 3])"
													style="padding: 0px 4px"
													rounded
													content-class="rounded-lg border"
													bottom
												>
													<template #activator="{ on, attrs }">
														<div
															@click.stop
															v-on="on"
															v-bind="attrs"
															class="px-1 d-flex justify-space-between align-center"
															:style="{
																border: `1px solid ${StatusInfo[row.status]?.color || 'red'}`,
																borderRadius: '5px',
																maxWidth: '63px',
															}"
														>
															<span
																class="fw-600"
																:style="{
																	color: StatusInfo[row.status]?.color || 'red',
																}"
															>
																{{ StatusInfo[row.status]?.title || row.status }}
															</span>
															<v-icon
																v-if="ANDEvery([row.status != 1, row.status != 2, row.status != 3])"
																right
																style="font-size: 22px"
																class="px-0 mx-0"
																:color="StatusInfo[row.status]?.color || 'red'"
																small
															>
																{{ attrs["aria-expanded"] == "true" ? "mdi-menu-up" : "mdi-menu-down" }}
															</v-icon>
														</div>
													</template>

													<v-list
														v-if="row.status != 1 && row.status != 2 && row.status != 3"
														class="py-0 rounded-lg px-1"
														elevation
													>
														<v-list-item
															class="list-item align-center cursor-pointer p-1 fw-500"
															v-for="(item, index) in statusList"
															:key="index"
															@click="updateRowStatus(item.value, row.id)"
														>
															<div
																class="mr-1"
																:class="item.color"
																:style="{
																	height: '15px',
																	width: '15px',
																	borderRadius: '50%',
																}"
															></div>
															{{ item.title }}
														</v-list-item>
													</v-list>
												</v-menu>
											</td>

											<!-- Indexed -->
											<td
												:class="activeRowId == row.id ? 'px-2' : 'px-2'"
												width="10px"
												class="td_column cursor-default simple-table-td border-top-light-grey"
											>
												<v-chip
													@click.stop
													label
													outlined
													class="ml-1"
													:color="row.index == 1 ? 'green darken-2' : 'red darken-2'"
												>
													<span class="line-clamp-2" style="max-width: 200px">
														{{ row.index == 1 ? "True" : "False" }}
													</span>
												</v-chip>
											</td>

											<!-- Target URL -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												width="250px"
												style="padding: 0; white-space: break-spaces"
												class="td_column cursor-default simple-table-td border-top-light-grey"
												:class="activeRowId == row.id ? 'px-2' : 'px-4'"
											>
												<template v-if="activeRowId == row.id">
													<template v-for="(target, idex) in updateBacklinkData.target_url">
														<TextInput
															:key="idex + '-dblfield'"
															dense
															hide-top-margin
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															customClass="my-1 backlink-text-inp input-with-appendInner"
															id="target_url"
															placeholder="Target Url"
															v-model.trim="updateBacklinkData.target_url[idex]"
															:rules="[vrules.validUrl(updateBacklinkData.target_url[idex], 'Target Url', true)]"
														>
															<template #appendInner>
																<v-tooltip top content-class="custom-top-tooltip">
																	<template #activator="{ on, attrs }">
																		<v-icon
																			v-on="on"
																			v-bind="attrs"
																			:color="updateBacklinkData.target_url.length > 1 ? 'red' : 'green'"
																			class="cursor-pointer ma-0 pa-0"
																			@click="handleMultiple(idex, 'target_url')"
																		>
																			{{ updateBacklinkData.target_url.length > 1 ? "mdi-delete" : "mdi-plus" }}
																		</v-icon>
																	</template>
																	<span>{{ updateBacklinkData.target_url.length > 1 ? "Delete" : "Add more" }}</span>
																</v-tooltip>
															</template>
														</TextInput>
													</template>
												</template>
												<template v-else>
													<template v-if="getValue(row, 'target_url.0.target_url')">
														<template v-for="(target, idex) in row.target_url">
															<v-tooltip
																v-if="target['target_url']"
																top
																:key="'target-url-' + idex"
																content-class="custom-top-tooltip"
															>
																<template #activator="{ on, attrs }">
																	<div
																		v-on="on"
																		v-bind="attrs"
																		style="height: 30px !important"
																		class="my-1 d-flex align-center text-truncate cursor-pointer"
																	>
																		{{ target["target_url"] }}
																	</div>
																</template>
																{{ target["target_url"] }}
															</v-tooltip>
														</template>
													</template>
													<em v-else class="text-muted fw-500">no Target URL</em>
												</template>
											</td>

											<!-- Anchor Text -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												width="250px"
												style="padding: 0"
												class="td_column cursor-default simple-table-td border-top-light-grey"
												:class="activeRowId == row.id ? 'px-2' : 'px-4'"
											>
												<template v-if="activeRowId == row.id">
													<template v-for="(anchor, idx) in updateBacklinkData.anchor_text">
														<TextInput
															:key="idx + '-dblfieldanchortext'"
															dense
															class="backlink-text-inp"
															hide-top-margin
															hide-details
															:disabled="pageLoading"
															:loading="pageLoading"
															customClass="my-1 input-with-appendInner"
															id="Anchor Text"
															placeholder="Anchor Text"
															v-model.trim="updateBacklinkData.anchor_text[idx]"
														>
															<template #appendInner>
																<v-tooltip top content-class="custom-top-tooltip">
																	<template #activator="{ on, attrs }">
																		<v-icon
																			v-on="on"
																			v-bind="attrs"
																			:color="updateBacklinkData.anchor_text.length > 1 ? 'red' : 'green'"
																			class="cursor-pointer ma-0 pa-0"
																			@click="handleMultiple(idx, 'anchor_text')"
																		>
																			{{ updateBacklinkData.anchor_text.length > 1 ? "mdi-delete" : "mdi-plus" }}
																		</v-icon>
																	</template>
																	<span>{{
																		updateBacklinkData.anchor_text.length > 1 ? "Delete" : "Add more"
																	}}</span>
																</v-tooltip>
															</template>
														</TextInput>
													</template>
												</template>
												<template v-else>
													<template v-if="getValue(row, 'target_url.0.anchor_text')">
														<template v-for="(anchor, idex) in row.target_url">
															<v-tooltip
																v-if="anchor['anchor_text']"
																top
																:key="'anchor_text-' + idex"
																conten
																t-class="custom-top-tooltip"
															>
																<template #activator="{ on, attrs }">
																	<div
																		v-on="on"
																		v-bind="attrs"
																		style="height: 30px !important"
																		class="my-1 d-flex align-center text-truncate cursor-pointer"
																	>
																		{{ anchor["anchor_text"] }}
																	</div>
																</template>
																{{ anchor["anchor_text"] }}
															</v-tooltip>
														</template>
													</template>
													<em v-else class="text-muted fw-500">no Anchor Text</em>
												</template>
											</td>

											<!-- DO Follow -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												width="250px"
												style="padding: 0"
												class="td_column cursor-default simple-table-td border-top-light-grey"
												:class="activeRowId == row.id ? 'px-2' : 'px-4'"
											>
												<template v-if="activeRowId == row.id">
													<template v-for="(follow, idx) in updateBacklinkData.do_follow">
														<SelectInput
															:key="idx + '-dblfielddofollow'"
															:items="[
																{ title: 'True', value: 1, icon: 'mdi-check', color: 'green' },
																{ title: 'False', value: 0, icon: 'mdi-close', color: 'red' },
															]"
															id="do-follow"
															hide-details
															name="text"
															:loading="pageLoading"
															placeholder="Do follow"
															customClass="my-1 same-height input-with-appendInner"
															itemText="title"
															value="value"
															noStyleItem="title"
															v-model.trim="updateBacklinkData.do_follow[idx]"
															:menu-props="{ offsetY: true }"
															inlineStyle="{ maxHeight: '30px !important'; }"
														>
															<template #appendInner>
																<v-tooltip top content-class="custom-top-tooltip">
																	<template #activator="{ on, attrs }">
																		<v-icon
																			v-on="on"
																			v-bind="attrs"
																			:color="updateBacklinkData.do_follow.length > 1 ? 'red' : 'green'"
																			class="cursor-pointer ma-0 pa-0"
																			@click="handleMultiple(idx, 'do_follow')"
																		>
																			{{ updateBacklinkData.do_follow.length > 1 ? "mdi-delete" : "mdi-plus" }}
																		</v-icon>
																	</template>
																	<span>{{ updateBacklinkData.do_follow.length > 1 ? "Delete" : "Add more" }}</span>
																</v-tooltip>
															</template>
														</SelectInput>
													</template>
												</template>
												<template v-else>
													<template v-if="getValue(row, 'target_url.0.do_follow') != null">
														<template v-for="(follow, idex) in row.target_url">
															<div :key="'target-url-' + idex" class="d-flex align-center justify-content-start">
																<v-chip
																	label
																	outlined
																	:color="follow['do_follow'] == 1 ? 'green' : 'red'"
																	class="px-1 my-1 cursor-pointer"
																>
																	{{ follow["do_follow"] == 1 ? "True" : "False" }}
																</v-chip>
															</div>
														</template>
													</template>
													<em v-else class="text-muted fw-500">no Do Follow</em>
												</template>
											</td>

											<!-- date -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												width="250px"
												style="padding: 0"
												class="td_column cursor-default simple-table-td border-top-light-grey"
												:class="activeRowId == row.id ? 'px-2' : 'px-4'"
											>
												<DatePicker
													hide-details
													hideTopMargin
													v-if="activeRowId == row.id"
													style="margin: 0 !important; padding: 0 !important"
													:disabled="pageLoading"
													:loading="pageLoading"
													id="date"
													hidePrefixIcon
													customClass="backlink-text-inp"
													v-model.trim="updateBacklinkData.date"
												></DatePicker>
												<template v-else>
													<span class="line-clamp-2" v-if="row.date" style="max-width: 200px">
														{{ formatDate(row.date) }}
													</span>
													<em v-else>no date</em>
												</template>
											</td>

											<!-- Activity -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												width="250px"
												style="padding: 0"
												class="td_column cursor-default simple-table-td border-top-light-grey"
												:class="activeRowId == row.id ? 'px-2' : 'px-4'"
											>
												<div @click.stop v-if="activeRowId == row.id">
													<SelectInput
														:items.sync="seoActivityList"
														id="activity"
														hide-details
														style="margin: 0 !important; padding: 0 !important"
														name="text"
														class="my-2"
														itemValue="value"
														itemText="title"
														placeholder="Activity"
														customClass="backlink-text-inp"
														v-model.trim="updateBacklinkData.activity"
														:menu-props="{ offsetY: true }"
														noStyleItem="title"
													></SelectInput>
												</div>
												<template v-else>
													<template v-if="row.activity">
														<!-- <span class="line-clamp-2" style="max-width: 200px">
															{{ row.activity }}
														</span> -->
														{{
															seoActivityList.find((v) => v.value === row.activity || v.title === row.activity)
																?.title
														}}
													</template>
													<em v-else class="text-muted">no activity</em>
												</template>
											</td>

											<!-- Country -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												width="250px"
												style="padding: 0"
												class="td_column cursor-default simple-table-td border-top-light-grey"
												:class="activeRowId == row.id ? 'px-2' : 'px-2'"
											>
												<v-select
													style="margin: 0 !important; padding: 0 !important"
													outlined
													:items.sync="countryList"
													id="country"
													v-if="activeRowId == row.id"
													hide-details
													class="mt-3"
													placeholder="Country"
													v-model.trim="updateBacklinkData.country"
													:menu-props="{ offsetY: true }"
												>
													<template #item="{ item }">
														<div class="d-flex py-1 align-center justify-center">
															<div class="mr-2">
																<v-icon
																	style="width: 15px; height: 15px"
																	v-if="item.value == 'Global'"
																	color="blue darken-3"
																	>mdi-earth</v-icon
																>
																<v-icon v-else :class="item.flag" class="vti__flag"> </v-icon>
															</div>
															<div :class="{ 'ml-1': item.value == 'Global' }">
																<span class="fw-500">
																	{{ item.text }}
																</span>
															</div>
														</div>
													</template>
												</v-select>
												<template v-else>
													<div class="text-truncate">
														<v-chip label outlined v-if="row.country" color="deep-purple">
															{{ row.country }}
															<!-- {{ countryText(row.country) || row.country }} -->
														</v-chip>
														<em v-else class="text-muted">no country</em>
													</div>
												</template>
											</td>

											<!-- Category -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												width="250px"
												style="padding: 0"
												class="td_column cursor-default simple-table-td border-top-light-grey"
												:class="activeRowId == row.id ? 'px-2' : 'px-4'"
											>
												<SelectInput
													v-if="activeRowId == row.id"
													:items.sync="seoCategoryList"
													id="category"
													hide-details
													name="text"
													class="my-2"
													:loading="pageLoading"
													style="margin: 0 !important; padding: 0 !important"
													placeholder="Category"
													itemText="title"
													value="value"
													customClass="backlink-text-inp"
													noStyleItem="title"
													v-model.trim="updateBacklinkData.category"
													:menu-props="{ offsetY: true }"
												></SelectInput>
												<template v-else>
													<span class="line-clamp-2" style="max-width: 200px">
														{{ seoCategoryList.find((v) => v.value === row.category)?.title }}
													</span>
												</template>
											</td>

											<!-- Executive -->
											<td
												@click.stop="
													getPermission('backlink_library_settings:update') && editRow($event, index, row.id)
												"
												width="250px"
												style="padding: 0"
												class="td_column cursor-default simple-table-td border-top-light-grey"
												:class="activeRowId == row.id ? 'px-2' : 'px-2'"
											>
												<template v-if="row.added_by">
													<span class="line-clamp-2" style="max-width: 200px"> {{ row.added_by }} </span>
												</template>
												<em v-else>no executive</em>
											</td>

											<!-- <td class="simple-table-td d-flex justify-end px-3  border-top-light-grey" max-height="42px">
										<v-btn
											class="mx-2"
											color="red lighten-1"
											v-if="getPermission('subtask::delete')"
											fab
											dark
											x-small
											@click="openDeleteConfirm(index, row)"
										>
											<v-icon small dark> mdi-trash-can-outline</v-icon>
										</v-btn>
									</td> -->
										</tr>
									</template>

									<template
										v-if="pageLoading == false && tbody && Array.isArray(tbody) && tbody.length == 0"
									>
										<tr>
											<td :colspan="8">
												<p class="m-0 row-not-found text-center py-3">
													<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
													Uhh... There <span class="text-lowercase">are no file at the moment.</span>
												</p>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</v-form>
					</div>
				</div>
				<!-- <v-row class="my-2">
					<v-col md="6" class="">
						<p class="m-0">kkkkkk</p>
					</v-col>
					<v-col md="6" class="text-right">
						v-model="currentPage"
						:length="totalPage"
						<v-pagination
							color="blue darken-4"
							total-visible="9"
							:length="5"
							class=""
						></v-pagination>
						@input="updatePagination"
					</v-col>
				</v-row> -->

				<v-row v-if="tbody.length">
					<v-col md="6" class="my-auto">
						<p class="m-0">{{ showingString }}</p>
					</v-col>
					<v-col md="6" class="text-right">
						<v-pagination
							color="blue darken-4"
							total-visible="9"
							v-model="page"
							:length="totalPage"
							@input="updatePagination($event)"
						></v-pagination>
					</v-col>
				</v-row>

				<DeleteTemplate
					type="Backlink"
					v-if="deleteDialog && getPermission('backlink_library_settings:delete')"
					delete-text=""
					@success="deleteBackLink()"
					@close="deleteDialog = false"
					:delete-dialog="deleteDialog"
					:delete-url="deleteURL"
					delete-note="delete backLink"
				>
				</DeleteTemplate>
				<!-- http://127.0.0.1:8000/api/backlinkseo/import -->
				<ImportDialog
					endpoint="backlinkseo/import"
					type="backlink"
					title="Backlink"
					v-if="importDialog && getPermission('backlink_library_settings:create')"
					:import-dialog.sync="importDialog"
					v-on:close="importDialog = false"
					v-on:refress="getBacklinkUpdatedList"
					:project-data-id="projectId"
					:seo-data-id="seoId"
					sample-file="/media/sample-files/Sample_Backlink.csv"
				></ImportDialog>
				<!-- @delete-row="handleDeleteRow" -->
			</div>
		</div>
	</div>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
;
<script>
import ImportDialog from "@/view/components/ImportDialogSEOTabs.vue";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import SelectInput from "@/view/components/SelectInput";
import { cloneDeep } from "lodash";
import DatePicker from "@/view/components/DatePicker";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import AddMultipleBackLink from "@/view/module/setting/AddMultipleBackLink";
import DateRangePicker from "@/view/components/DateRangePickerV2";
import { POST, GET, PUT, QUERY } from "@/core/services/store/request.module";
import "@/core/event-bus/click-outside";
import HeightMixin from "@/core/mixins/height.mixin";
import ListingMixin from "@/core/mixins/listing.mixin";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import objectPath from "object-path";

import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
	SET_COUNT,
	SET_SEARCH_BAR,
	SET_LOADING,
} from "@/core/services/store/listing.module";

export default {
	name: "Domain-Backlink",
	mixins: [HeightMixin, ListingMixin],
	props: {
		seoActivityList: {
			type: Array,
			default: () => [],
		},
		seoCategoryList: {
			type: Array,
			default: () => [],
		},
		projectId: {
			type: Number,
			default: 0,
		},
		fileName: {
			type: String,
			default: "",
		},
	},
	components: {
		Dialog,
		TextInput,
		DatePicker,
		DeleteTemplate,
		AddMultipleBackLink,
		SelectInput /* ,ShowPrice,PriceInput  */,
		ImportDialog,
		// PriceInput,
		// ShowTextAreaValue,
		// ShowTextAreaValue,
		DateRangePicker,
	},
	data() {
		return {
			skipBottomHeight: 61,
			ddwidth: 1200,
			importDialog: false,
			date_range: [],
			internal: true,
			about_tab: true,
			pageTitle: "Backlink",
			contract_tab: true,
			dialogSearch: null,
			productList: [],
			deleteURL: null,
			team_tab: true,
			competitors_tab: true,
			credentials_tab: true,
			status: null,
			backlinkActivity: null,
			targetUrlFilter: null,
			anchorTextFilter: null,
			endpoint: "seo/seoId/project/projectId/list-backlink",
			domainList: [
				{
					text: "https://pictogrammers.com",
					value: "https://pictogrammers.com",
				},
				{
					text: "https://google.com",
					value: "https://google.com",
				},
			],
			backlink_list1: [
				// {
				// 	id: null,
				// 	isEditable: false,
				// 	domain: "https://pictogrammers.com",
				// 	live_url: "",
				// 	target_url: "",
				// 	date: "2023-08-16",
				// 	do_follow: "No",
				// 	anchor_text: "",
				// 	indexed: "Yes",
				// 	category: "editorial",
				// 	activity: "article",
				// 	country: "SG",
				// 	status: "Found",
				// },
			],

			backlink_list: [
				{
					id: null,
					isEditable: false,
					domain: "",
					live_url: "",
					target_url: "",
					date: "",
					do_follow: "",
					anchor_text: "",
					indexed: "",
					activity: "",
					country: "",
					category: "",
					executive: "",
				},
			],
			updateBacklinkData: {
				id: null,
				domain: "",
				live_url: "",
				target_url: "",
				date: "",
				do_follow: "",
				anchor_text: "",
				indexed: "",
				activity: "",
				country: "",
				category: "",
				executive: "",
			},
			backlinks_status: [
				{
					id: 174,
					text: "All Backlinks",
					value: null,
					type: 35,
					status_color: "blue",
					count: 0,
					status_count: 0,
				},
				{
					id: 175,
					text: "Active",
					value: 1,
					type: 35,
					status_color: "green",
					count: 0,
					status_count: 0,
				},
				{
					id: 176,
					text: "Lost",
					value: 2,
					type: 35,
					status_color: "red",
					count: 0,
					status_count: 0,
				},
				{
					id: 177,
					text: "Pending",
					value: 3,
					type: 35,
					status_color: "orange",
					count: 0,
					status_count: 0,
				},
			],
			statusList: [
				{
					title: "Active",
					value: 1,
					color: "green",
				},
				{
					title: "Lost",
					value: 2,
					color: "red",
				},
				{
					title: "Pending",
					value: 3,
					color: "orange",
				},
			],
			statusLists: [
				{
					id: 174,
					text: "Total Backlinks",
					value: "all",
					type: 35,
					status_color: "blue",
					count: 0,
					status_count: 100,
				},
				{
					id: 175,
					text: "Last Month Backlinks",
					value: "1",
					type: 35,
					status_color: "red",
					count: 0,
					status_count: "10",
				},
				{
					id: 176,
					text: "This Month pending/Assigned",
					value: "2",
					type: 35,
					status_color: "green",
					count: 0,
					status_count: "50 / 100",
				},
			],
			newSite: {
				domain: null,
				category: null,
			},
			keywordList: [],
			deleteIndex: null,
			deleteDialog: false,
			countryList: [
				{
					text: "Singapore",
					value: "Singapore",
					flag: "sg",
				},
				{
					text: "Malaysia",
					value: "Malaysia",
					flag: "my",
				},
				{
					text: "United State",
					value: "United State",
					flag: "sg",
				},
				{
					text: "Australia",
					value: "Australia",
					flag: "au",
				},
				{
					text: "Nigeria",
					value: "Nigeria",
					flag: "ng",
				},
				{
					text: "Vietnam",
					value: "Vietnam",
					flag: "vn",
				},
				{
					text: "Thailand",
					value: "Thailand",
					flag: "th",
				},
				{
					text: "Global",
					value: "Global",
					flag: "Global",
				},
			],
			backlink_library: [
				{
					domain: "https://globaledu.com.sg",
					category: "Editorial	",
					country: "Singapore",
					added_by: "Sagar Kumar",
				},
				{
					domain: "https://globaledu.com.sg",
					category: "Editorial	",
					country: "Singapore",
					added_by: "Sagar Kumar",
				},
			],
			createBackLinkDialog: false,
			showSummary: false,
			get_selectedRows: [],
			activeRowId: null,
			seoId: null,
			page: 1,
			timer: null,
			backlinkFormValidity: true,
			sortdata: {
				column: null,
				icon: "mdi-filter-variant",
				order: null,
			},
			StatusInfo: {
				1: { color: "green", title: "Active" },
				2: { color: "red", title: "Lost" },
				3: { color: "orange", title: "Pending" },
			},
		};
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
	},
	mounted() {
		// this.getBacklinkUpdatedList(this.seoId);
		this.$store.dispatch(SET_SEARCH_BAR, false);
		this.getSettings();
	},
	beforeMount() {
		const _id = this.$route.params.id;
		this.seoId = _id;
		if (this.seoId) {
			let _endpoint = this.endpoint.replace("seoId", this.seoId);
			_endpoint = _endpoint.replace("projectId", this.projectId);
			this.endpoint = _endpoint;
		}
		let query = this.$route.query;
		if (query && Object.keys(query).length > 1) {
			this.targetUrlFilter = query.target_url;
			this.anchorTextFilter = query.anchor_text;
			this.backlinkActivity = query.activity;
			this.date_range =
				(query.start_date && query.end_date && [query.start_date, query.end_date]) || [];
			this.status = +query.status || null;
			this.page = +query.page || 1;

			const sort = query.sort;
			if (sort) {
				const sortArr = sort.split(":");
				this.sortBacklink(sortArr[1], sortArr[2], "beforeMount");
			}
		}
	},
	watch: {
		count(param) {
			let allIndex = this.backlinks_status.findIndex((v) => v.value == null);
			let activeIndex = this.backlinks_status.findIndex((v) => v.value == 1);
			let lostIndex = this.backlinks_status.findIndex((v) => v.value == 2);
			let pendingIndex = this.backlinks_status.findIndex((v) => v.value == 3);

			if (param) {
				if (allIndex > -1) {
					this.backlinks_status[allIndex].count = objectPath.get(param, "0.total_count") || 0;
				}
				if (activeIndex > -1) {
					this.backlinks_status[activeIndex].count = objectPath.get(param, "0.activeCount") || 0;
				}
				if (lostIndex > -1) {
					this.backlinks_status[lostIndex].count = objectPath.get(param, "0.lostCount") || 0;
				}
				if (pendingIndex > -1) {
					this.backlinks_status[pendingIndex].count = objectPath.get(param, "0.pendingCount") || 0;
				}
			}
		},
		currentPage(param) {
			this.page = param;
		},
		/* $route: {
			deep: true,
			handler(param) {
				if (objectPath.get(param, "tab") && objectPath.get(param, "tab") != "backlink") {
					let query = this.$route.query;

					console.log("asssssssssdffffffffffff");

					delete query.start_date;
					delete query.end_date;

					this.$router.push({
						name: "seo-detail",
						params: this.seoId,
						query: { ...query, page: 1, t: new Date().getTime() },
					});
				}
			},
		}, */
	},
	methods: {
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		handleMultiple(fieldindex) {
			/* const fields = ["target_url", "anchor_text", "do_follow"]; */
			if (this.updateBacklinkData["target_url"].length < 2) {
				this.updateBacklinkData["target_url"].push("");
			} else if (this.updateBacklinkData["target_url"].length == 2) {
				this.updateBacklinkData["target_url"].splice(fieldindex, 1);
			}
			if (this.updateBacklinkData["anchor_text"].length < 2) {
				this.updateBacklinkData["anchor_text"].push("");
			} else if (this.updateBacklinkData["anchor_text"].length == 2) {
				this.updateBacklinkData["anchor_text"].splice(fieldindex, 1);
			}
			if (this.updateBacklinkData["do_follow"].length < 2) {
				this.updateBacklinkData["do_follow"].push("");
			} else if (this.updateBacklinkData["do_follow"].length == 2) {
				this.updateBacklinkData["do_follow"].splice(fieldindex, 1);
			}
		},
		countryText(countryText) {
			let country = this.countryList.find(
				(row) => row.value == countryText
			); /*  find(this.countryList, (row) => row.value == countryText) */
			return country ? country.text : null;
		},
		sortBacklink(column, sortType, occ = "") {
			let icon, order;
			if (occ == "") {
				icon = sortType == "desc" ? "mdi-sort-ascending" : "mdi-sort-descending";
				order = sortType == "desc" ? "asc" : "desc";
				const { query, params, name } = this.$route;
				this.$router.push({ name, params, query: { ...query, sort: `${name}:${column}:${order}` } });
				this.getListing();
			} else {
				icon = sortType == "desc" ? "mdi-sort-descending" : "mdi-sort-ascending";
				order = sortType;
			}

			this.sortdata.column = column;
			this.sortdata.icon = icon;
			this.sortdata.order = order;
		},
		searchWithDate() {
			if (this.date_range == null || (Array.isArray(this.date_range) && this.date_range.length > 1)) {
				this.commanFilter();
			}
		},
		updateRowStatus(status, id) {
			this.$store.commit(SET_LOADING, true);
			this.$store
				.dispatch(PUT, { url: `seo/project/status-backlink/${id}`, data: { status } })
				.then(() => {
					this.getListing();
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.$store.commit(SET_LOADING, false);
				});
		},
		exportCSV() {
			ApiService.setHeader();
			let downloadURL =
				process.env.VUE_APP_API_URL +
				`backlinkseo-export?project_id=${this.projectId}&seo_id=${this.seoId}`;
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			downloadURL.searchParams.append("token", token);

			const project_name = this.fileName;

			const customName =
				project_name + " - backlink (" + this.formatDateTimeRawWithoutTime(new Date()) + ").xlsx";

			fetch(downloadURL)
				.then((response) => response.blob())
				.then((blob) => {
					const blobURL = URL.createObjectURL(blob);
					const anchor = document.createElement("a");
					anchor.href = blobURL;
					anchor.setAttribute("download", customName);
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					/* To not to keep the reference to the file any longer. */
					URL.revokeObjectURL(blobURL);
				});
		},
		getBacklinkUpdatedList() {
			let query = this.$route.query;

			if (query && Object.keys(query).length) {
				this.$router.push({
					name: "seo-detail",
					params: this.seoId,
					query: {
						...query,
						tab: "backlink",
						page: 1,
						t: new Date().getTime(),
					},
				});
			}

			this.getListing();
			// const _this = this;
			// _this.$store
			// 	.dispatch(GET, { url: `seo/${seoId}/project/${this.projectId}/list-backlink` })
			// 	.then((data) => {
			// 		_this.backlink_list = data.tbody;
			// 	})
			// 	.catch((error) => {
			// 		console.log({ error });
			// 	})
			// 	.finally(() => {
			// 		_this.pageLoading = false;
			// 	});
		},
		textSearchFilter() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.commanFilter();
			}, 500);
		},
		// goToBackLinkLibrary() {
		// this.$router.push({
		// 	name: "setting",

		// 	query: {
		// 		tab: "backlink-library",
		// 	},
		// });
		// this.createBackLinkDialog = true;
		// },
		// addRow(index) {
		// 	if (this.backlink_list.length - 1 == index) {
		// 		this.backlink_list.push({
		// 			id: null,
		// 			isEditable: false,
		// 			domain: "",
		// 			target_url: "",
		// 			date: "",
		// 			do_follow: "",
		// 			anchor_text: "",
		// 			indexed: "",
		// 			category: "",
		// 			activity: "",
		// 			country: "",
		// 		});
		// 	}
		// },
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				// this.$emit("reload:content", true);
				this.filterRows();
				// this.allFiltersStatus();
				// this.statusFilterRows();
			});
		},
		clickoutside() {
			this.activeRowId = null;
		},
		commanFilter() {
			let filter = {
				target_url: this.targetUrlFilter,
				anchor_text: this.anchorTextFilter,
				start_date: this.date_range ? this.date_range[0] : this.date_range,
				end_date: this.date_range ? this.date_range[1] : this.date_range,
				activity: this.backlinkActivity,
				status: this.status,
				page: 1,
				search: null,
				sort_column: this.sortdata.column || null,
				sort_order: this.sortdata.order || null,
			};
			this.$store.commit(SET_LOADING, true);
			this.allFiltersSetting(filter);
			this.$store
				.dispatch(QUERY, {
					url: this.endpoint,
					data: {
						...filter,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.currentPage);
					this.$store.commit(SET_TOTAL_PAGE, data.total_pages);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					this.$store.commit(SET_COUNT, data.count);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.$store.commit(SET_LOADING, false);
				});
		},
		allFiltersSetting(filter) {
			const { name, query } = this.$route;
			this.$router.push({
				name,
				params: this.seoId,
				query: {
					...query,
					tab: "backlink",
					...filter,
					t: new Date().getTime(),
				},
			});
		},
		deleteBackLink() {
			this.backlink_list.splice(this.deleteIndex, 1);
			this.deleteDialog = false;
			if (this.totalPage > 1 && this.tbody.length == 1) {
				const { name, query } = this.$route;
				this.$router.replace({
					name,
					params: this.seoId,
					query: {
						...query,
						tab: "backlink",
						t: new Date().getTime(),
						page: this.totalPage - 1,
					},
				});
			}
			this.getListing();
		},
		deleteConfirm(backlinkId, index) {
			this.deleteURL = `seo/project/delete-backlink/${backlinkId}`;
			this.deleteIndex = index;
			this.deleteDialog = true;
		},
		domainChange() {
			this.backlink_list[0] = this.backlink_list1[0];
		},
		assignArrayValue(arr, key) {
			if (arr.length) {
				return arr.map((tr) => tr[key]);
			} else {
				return [""];
			}
		},
		editRow(event, index, rowid) {
			/* if (this.activeRowId && this.activeRowId != rowid) {
				this.submitFunction();
			} */
			if (this.activeRowId == rowid) {
				return false;
			}

			this.activeRowId = rowid;

			let dataToUpdate = this.tbody.find((v) => v.id == rowid);
			this.updateBacklinkData = {
				...cloneDeep(dataToUpdate),
				target_url: this.assignArrayValue(dataToUpdate.target_url, "target_url"),
				anchor_text: this.assignArrayValue(dataToUpdate.target_url, "anchor_text"),
				do_follow: this.assignArrayValue(dataToUpdate.target_url, "do_follow"),
			};

			/* this.isOutside = false;
			let element = event.target;
			let _line_items = cloneDeep(this.backlink_list);
			_line_items = map(_line_items, (row) => {
				return { ...row, isEditable: false };
			});
			//this.backlink_list = _line_items;
			// this.line_items[index].isEditable =true;
			_line_items[index].isEditable = true;
			this.backlink_list = _line_items;
			this.$nextTick(() => {
				const inputarea = element.querySelector("input");
				const textarea = element.querySelector("textarea");
				if (inputarea) {
					inputarea.focus();
					inputarea.select();
				}
				if (textarea) {
					textarea.focus();
					textarea.select();
				}
			});
			if (element.nodeName == "INPUT") {
				element.select();
			}
			if (element.nodeName == "TEXTAREA") {
				element.select();
			}
			if (Array.isArray(this.backlink_list) && this.backlink_list.length == index + 1) {
				this.openAddDialog();
			} */
		},
		submitFunction() {
			const _this = this;

			let activeId = _this.activeRowId;

			if (!this.backlinkFormValidity) {
				this.$store.commit(SET_ERROR, [
					{ model: true, message: "Enter valid Live URL and Target URL." },
				]);
			}

			if (activeId && this.backlinkFormValidity) {
				_this.activeRowId = null;
				// const _line_items = map(_this.backlink_list, (row) => {
				// 	return { ...row, isEditable: false };
				// });

				// _this.backlink_list = _line_items;

				// const updatevalue = _this.backlink_list.find((v) => {
				// 	return v.id == _this.activeRowId;
				// });
				this.$store.commit(SET_LOADING, true);
				_this.$store
					.dispatch(PUT, {
						url: `seo/project/update-backlink/${activeId}`,
						data: this.updateBacklinkData,
					})
					.then(() => {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success! Backlink updated successfully.",
							},
						]);
						_this.getListing();
					})
					.catch((error) => {
						console.log({ error });
					})
					.finally(() => {
						this.$store.commit(SET_LOADING, false);
					});
			}
		},
		// tabClickedQty(index) {
		// 	this.tabClicked(index);
		// },
		openAddDialog() {
			this.backlink_list.push({
				subtask_uuid: null,
				id: null,
				isEditable: false,
				domain: "",
				target_url: "",
				anchor_text: "",
				date: "",
				do_follow: "",
				category: "",
				activity: "",
				country: "",
				remark: "",
				status: "",
			});
		},
		/* tabClickedQty(index) {
			if (Array.isArray(this.backlink_list) && this.backlink_list.length == index + 1) {
				this.openAddDialog();
			}
			const _line_items = map(this.backlink_list, (row) => {
				return { ...row, isEditable: false };
			});
			this.backlink_list = _line_items;
			this.backlink_list[index + 1].isEditable = true;
		}, */
		backlinkdata(data = []) {
			this.get_selectedRows = data;
		},
		createOrUpdateBacklink() {
			const seoId = this.$route.params.id;
			const url = `seo/${seoId}/project/${this.projectId}/add-backlink`;
			const _this = this;
			this.$store.commit(SET_LOADING, true);
			_this.$store
				.dispatch(POST, { url, data: { seo_backlink: _this.get_selectedRows } })
				.then(() => {
					this.getBacklinkUpdatedList();
					this.backlinkdata();
					this.createBackLinkDialog = false;
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Backlink added successfully.",
						},
					]);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.$store.commit(SET_LOADING, false);
				});
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "lead-setting" })
				.then((data) => {
					if (data.users && Array.isArray(data.users)) {
						const Sales_Users = data.users.filter((user) => user.users_type == "seo");
						_this.usersList = Sales_Users || [];
					}
					_this.categoryList = data.category || [];
				})
				.catch((error) => {
					console.log({ error });
				});
			/* .finally(() => {
					_this.pageLoading = false;
				}); */
		},
		// backlinkFilterList(page) {
		// 	const seoId = this.$route.params.id;
		// 	let formate =
		// 		this.backlinkFilter.filter.daterange && this.backlinkFilter.filter.daterange.length > 0
		// 			? this.backlinkFilter.filter.daterange
		// 			: this.$route.query.date;
		// 	let filter = {
		// 		daterange: formate,
		// 		activity: this.backlinkFilter.activity || null,
		// 		target_url: this.backlinkFilter.target_url || null,
		// 		anchor_text: this.backlinkFilter.anchor_text || null,
		// 	};
		// 	this.$store
		// 		.dispatch(QUERY, {
		// 			url: `seo/${seoId}/project/${this.projectId}/list-backlink`,
		// 			page: page,
		// 			data: filter,
		// 		})
		// 		.then((data) => {
		// 			this.backlink_list = data.data;
		// 			this.showing_string = data.showingString;
		// 			this.totalPages = Math.ceil(data.totalRows / data.per_page);
		// 			this.currentPage = data.current_page;
		// 			this.totalRows = data.totalRows;
		// 			this.rowsOffset = data.offset;
		// 			this.showingFrom = data.showingFrom;
		// 			this.showingTo = data.showingTo;
		// 			this.$store.commit(SET_FILTER, data.data.filter);
		// 		})
		// 		.catch((error) => {
		// 			console.log({
		// 				error,
		// 			});
		// 		})
		// 		.finally(() => {
		// 			this.pageLoading = false;
		// 		});
		// },
	},
};
</script>

<style>
.listing-select .v-select__selection--comma {
	margin-top: 0px;
	margin-bottom: 0px;
}
</style>
