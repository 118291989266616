<template>
	<div @click="copyToClipboard(value())">
		<span v-if="tooltip">
			<v-tooltip top content-class="custom-top-tooltip">
				<template #activator="{ on, attrs }">
					<span
						:class="[{ 'text-truncate': truncate, 'cursor-pointer': clickToCopy }, classes]"
						v-html="value()"
						v-on="on"
						v-bind="attrs"
					>
					</span>
				</template>
				{{ getTooltipValue() }}
			</v-tooltip>
		</span>
		<span
			v-else
			:class="[{ 'text-truncate': truncate, 'cursor-pointer': clickToCopy }, classes]"
			v-html="value()"
		>
		</span>
	</div>
</template>
<script>
import objectPath from "object-path";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
export default {
	name: "show-value",
	title: "Show Value",
	props: {
		object: {
			type: Object,
			default() {
				return new Object();
			},
		},
		customClass: {
			type: [Array, Object, String],
			default: () => new Object(),
		},
		objectKey: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: null,
		},
		truncate: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: Boolean,
			default: false,
		},
		tooltipValue: {
			type: String,
			default: null,
		},
		capitalize: {
			type: Boolean,
			default: true,
		},
		clickToCopy: {
			type: Boolean,
			default: false,
		},
		removeTags: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		value() {
			if (objectPath.has(this.object, this.objectKey)) {
				if (objectPath.get(this.object, this.objectKey)) {
					let value = objectPath.get(this.object, this.objectKey);
					if (this.removeTags) {
						value = value && value.replace(/(<([^>]+)>)/gi, "");
					}
					return typeof value == "string" && this.capitalize
						? value.charAt(0).toUpperCase() + value.slice(1)
						: value;
				} else {
					return `<em class="text-muted">No ${this.label}</em>`.toLowerCase();
				}
			} else {
				return `<em class="text-muted">No ${this.label}</em>`.toLowerCase();
			}
		},
		getTooltipValue() {
			if (objectPath.get(this.object, this.tooltipValue)) {
				console.log(objectPath.get(this.object, this.tooltipValue));
				return objectPath.get(this.object, this.tooltipValue);
			}
		},
		copyToClipboard(text) {
			if (this.clickToCopy) {
				navigator.clipboard.writeText(text).then(
					() => {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: `Success ! ${text} copied successfully.`,
							},
						]);
					},
					() => {
						this.$store.commit(SET_ERROR, [
							{
								model: true,
								message: `Error ! ${text} cannot copied.`,
							},
						]);
					}
				);
			}
		},
	},
	computed: {
		classes: {
			get() {
				return Array.isArray(this.customClass) ? this.customClass.join(" ") : this.customClass;
			},
		},
	},
};
</script>
