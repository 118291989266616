<template>
	<v-select
		:id="id"
		:label="label"
		:loading="loading"
		:disabled="disabled"
		:readonly="readonly"
		:items.sync="items"
		:rules="rules"
		:item-text="itemText"
		:item-value="itemValue"
		:dense="dense"
		:clearable="clearable"
		:hide-details="hideDetails"
		:height="height"
		:multiple="multiple"
		outlined
		:class="customClass"
		:style="[inlineStyle]"
		v-model="selectinput"
		:placeholder="placeholder"
		:menu-props="menuProps"
		:append-outer-icon="appendOuterIcon"
		@click:append-outer="$emit('click:append-outer', true)"
		@change="$emit('change', selectinput)"
	>
		<template #no-data>
			<v-list-item>
				<v-list-item-title v-html="`No ${placeholder} Found.`"></v-list-item-title>
			</v-list-item>
		</template>

		<template #append>
			<slot name="appendInner"></slot>
		</template>

		<template v-if="$scopedSlots['selected-item']" #selection="{ item }">
			<slot name="selected-item" :item="item"></slot>
		</template>

		<template v-if="Boolean(noStyleItem)" #item="{ item }">
			<div class="py-1">
				<v-icon class="mr-2" size="20" :color="item.color">{{ item.icon }}</v-icon>
				<span class="fw-500 text-capitalize">
					{{ showItem(item) }}
				</span>
			</div>
		</template>
	</v-select>
</template>
<script>
export default {
	name: "select-input",
	model: {
		prop: "value",
		event: "change",
	},
	props: {
		menuProps: {
			type: Object,
			// default: new Object()
			default: () => ({}),
		},
		noStyleItem: {
			type: String,
			default: "",
		},
		inlineStyle: {
			type: [Object, String],
			default: () => {},
		},
		value: {
			type: [Object, Number, String],
			default: null,
		},
		multiple: {
			type: Boolean,
			default: false,
		},
		items: {
			type: Array,
			required: true,
			default: () => {
				return [];
			},
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		customClass: {
			type: [String, Object],
			default: "mt-3 pt-0",
		},
		placeholder: {
			type: String,
			default: null,
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		clearable: { type: Boolean, default: false },
		itemText: {
			type: String,
			default: "text",
		},
		itemValue: {
			type: String,
			default: "value",
		},
		loading: {
			type: Boolean,
			default: false,
		},
		dense: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		align: {
			type: Boolean,
			default: false,
		},
		height: {
			type: [Number, String],
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler(param) {
				this.selectinput = param;
			},
		},
	},
	methods: {
		showItem(item) {
			if (typeof item == "object") {
				return item[this.noStyleItem];
			} else {
				return item;
			}
		},
	},
	data() {
		return {
			selectinput: null,
		};
	},
	mounted() {
		this.selectinput = this.value;
	},
};
</script>
