<template>
	<v-text-field
		:type="type"
		:id="id"
		ref="glc_crm_input_custom_component_ref"
		:label="label"
		:value="value"
		:loading="loading"
		:disabled="disabled"
		:readonly="readonly"
		:dense="dense"
		:hide-details="hideDetails"
		outlined
		:clearable="clearable"
		:maxLength="maxLength"
		:rules="rules"
		:debounce="debounce"
		:style="customStyle"
		:class="[{ 'mt-3 pt-0': !hideTopMargin }, customClass]"
		v-model.trim="textinput"
		:append-outer-icon="appendOuterIcon"
		:placeholder="placeholder"
		:prepend-icon="prependIcon"
		:prepend-inner-icon="prependInnerIcon"
		@click:append-outer="$emit('click:append-outer', true)"
		@onchange="$emit('onchange', true)"
		@keydown="$emit('keydown', $event)"
		@change="$emit('change', $event)"
		@input="$emit('input', $event)"
		@keypress="$emit('keypress', $event)"
		@keyup="$emit('keyup', true)"
		v-bind="vBindAttrs"
		v-on="vOn"
		@click:clear="$emit('click:clear')"
		@blur="$emit('blur')"
		@click:append="$emit('click:append', $event)"
	>
		<template #append>
			<div @click="$emit('click:append', $event)">
				<slot name="appendInner"></slot>
			</div>
		</template>
	</v-text-field>
</template>
<script>
export default {
	name: "text-input",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		type: {
			type: String,
			default: () => {
				return null;
			},
		},
		value: {
			type: [String, Number],
			default: null,
		},
		customClass: {
			type: [String, Object],
			default: null,
		},
		clearable: {
			type: Boolean,
		},
		maxLength: {
			type: [String, Number],
			default: undefined,
		},
		rules: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		customStyle: {
			type: Object,
			default: () => {},
		},
		hideTopMargin: {
			type: Boolean,
		},
		dense: {
			type: Boolean,
		},
		placeholder: {
			type: String,
			default: null,
		},
		inputRef: {
			type: String,
			default: "textInput",
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		vBindAttrs: {
			type: Object,
			default: () => {
				return {};
			},
		},
		vOn: {
			type: Object,
			default: () => {
				return {};
			},
		},
		hideDetails: {
			type: Boolean,
			default: false,
		},
		appendOuterIcon: {
			type: String,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		debounce: {
			type: Number,
			default: 1000,
		},
		prependIcon: {
			type: String,
			default: undefined,
		},
		prependInnerIcon: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			textinput: null,
			leftText: "ksdjfdf",
		};
	},
	watch: {
		value() {
			this.textinput = this.value;
		},
		textinput() {
			this.$emit("input", this.textinput);
		},
	},
	methods: {
		focus() {
			setTimeout(() => {
				this.$refs["glc_crm_input_custom_component_ref"].focus();
			}, 0);
		},
	},
	mounted() {
		this.textinput = this.value;
	},
};
</script>
